nav.sidebar {
    height: 100vh;
    box-sizing: border-box;
    background: #0e3a5e;
    color: white;
    
    box-shadow: 0 0 5px #0e3a5e;
    
}

.Sidebar {
    background-color: #0e3a5e;
    color: #fff;
}

.sidebar-logo {
    font-size: 16px;
    width: 90px;
}

.sidebar-warning {
    color: orange;
    cursor: default;
}

.sidebar-icon,
.sidebar-icon-arrowRight,
.sidebar-icon-arrowLeft {
    width: 1.25em;
    height: 1.25em;
    margin-right: 16px;
    cursor: pointer;
    user-select: none;
}

.sidebar-header {
    display: flex;
    align-items: center;
    padding: 16px;
}

.sidebar-items {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.sidebar-items .item {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    border-radius: 5px;
    transition: background-color 0.2s;
    cursor: pointer;
}

.sidebar-items a {
    text-decoration: none;
    color: #FFFFFF;

}

.sidebar-items .item:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.Sidebar.collapsed {
    width: 52px;
}

.Sidebar.collapsed .sidebar-icon,
.Sidebar.collapsed .sidebar-icon-arrowLeft
.Sidebar.collapsed .sidebar-icon-arrowRight {
    margin-right: 0px;
}

.Sidebar.collapsed .sidebar-text,
.Sidebar.collapsed .sidebar-logo,
.Sidebar.collapsed .sidebar-icon-arrowLeft {
    display: none;
}

/* hide when extended */
.Sidebar .sidebar-icon-arrowRight {
    display: none;
}

/* show when collapsed */
.Sidebar.collapsed .sidebar-icon-arrowRight {
    display: flex;
    margin-right: 0px;
}